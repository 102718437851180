export interface NewsCarouselConfig {
    /**
     * The aria label for the carousel container.
     * It should convey to screenreader users what the carousel is about.
     */
    carouselLabel: string;
    /**
     * The role description of the carousel container. English default: "carousel"
     */
    carouselDescription: string;
    /**
     * The role description for each slide container. English default: "slide"
     */
    slideDescription: string;
    /**
     * The middle word for every slide's aria-label, e.g. "1 of 3". English default: "of"
     */
    slideLabel: string;
    nextButtonLabel: string;
    previousButtonLabel: string;
}

export enum NewsCarouselImagePosition {
    Center = 'Center',
    Left = 'Left',
    Right = 'Right',
}

export interface NewsCarouselItem {
    heading: string;
    textSnippet: string;
    imageUrl: string;
    imagePosition?: NewsCarouselImagePosition;
    link: string;
}

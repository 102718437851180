import Hammer from '@egjs/hammerjs';
import { RefObject, useEffect, useRef } from 'react';

type Callback = () => void;

/**
* Swipe hook gives functionality to detect horizontal user swipe events on given container.
* Dependency: hammerjs (npm install hammerjs --save)
* 
* Functionality of hook can be extended by passing single callback for all events
* and pass type of hook to callback.
*
* Callback functions are stored using ref to avoid rerunning of useEffect.
* This avoids extra work of consumer component.
*
* @example Use the hook in a component like this:
* const swipeContainerRef = useRef();
* useHorizontalSwipe(swipeContainerRef, leftSwipeCallback, rightSwipeCallback)
* <div ref={swipeContainerRef}>Swipe hook will catch events for this container</div>
*/
export default function useHorizontalSwipe(elRef: RefObject<HTMLDivElement | undefined>, leftSwipe: Callback, rightSwipe: Callback) {
    const leftSwipeRef = useRef<Callback>();
    leftSwipeRef.current = leftSwipe;

    const rightSwipeRef = useRef<Callback>();
    rightSwipeRef.current = rightSwipe;

    useEffect(() => {
        if (!elRef || !elRef.current) {
            console.error('useHorizontalSwipe hook needs reference of swipe container');
            return;
        }

        const container = elRef.current;
        const HammerScroll = new Hammer.Manager(container);
        const Swipe = new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL });

        HammerScroll.add(Swipe);
        HammerScroll.on('swipeleft', () => leftSwipeRef.current!());
        HammerScroll.on('swiperight', () => rightSwipeRef.current!());

        return () => {
            HammerScroll.off('swipeleft');
            HammerScroll.off('swiperight');
        };
    }, [leftSwipeRef, rightSwipeRef]);
}

import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import * as React from 'react';

import { HomepageContentGerman } from '../components/homepage/homepage-content-de';
import { HomepageContentEnglish } from '../components/homepage/homepage-content-en';
import Layout from '../components/layout/layout';
import { NewsCarousel } from '../components/news-carousel/news-carousel';
import {
    NEWS_CAROUSEL_CONFIG_DE,
    NEWS_CAROUSEL_CONFIG_EN,
    NEWS_CAROUSEL_ITEMS_DE,
    NEWS_CAROUSEL_ITEMS_EN,
} from '../const/carousel.const';
import { allPostsParagraph, narrowContent, wideContent } from '../styles/index.module.css';
import { getRelativePath } from '../utils/app.utils';

export default function IndexPage() {
    const { locale } = useLocalization();
    // This is necessary so that static html file created with static site generation includes correct meta data.
    const relativePath = getRelativePath(locale, '/');
    const carouselConfig = locale === 'en' ? NEWS_CAROUSEL_CONFIG_EN : NEWS_CAROUSEL_CONFIG_DE;
    const carouselItems = locale === 'en' ? NEWS_CAROUSEL_ITEMS_EN : NEWS_CAROUSEL_ITEMS_DE;
    const allPostsHint = locale === 'en' ? 'Here you\'ll find' : 'Hier findest du';
    const allPostsLink = locale === 'en' ? 'all blog posts' : 'alle Blog-Artikel';

    return (
        <Layout
            langSwitchPath='/'
            isHomepage={true}
            relativePath={relativePath}
        >
            <h1 className="sr-only">Web Dev Blog</h1>
            <div className={wideContent}>
                <NewsCarousel config={carouselConfig} newsItems={carouselItems} />
            </div>
            <div className={narrowContent}>
                <p className={allPostsParagraph}>
                    {allPostsHint}
                    <span> </span>
                    <LocalizedLink
                        to="/post-list"
                        language={locale}
                    >
                        {allPostsLink}
                    </LocalizedLink>.
                </p>
                <hr />
                {locale === 'en' ? <HomepageContentEnglish /> : <HomepageContentGerman />}
                <hr />
            </div>
        </Layout>
    );
};

import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
import * as React from 'react';

import { imageWrapper } from './homepage-content.module.css';

export const HomepageContentGerman: React.FunctionComponent = () => {
    return (
        <>
            <h2>Über diese Seite</h2>
            <p>
                Hallo, mein Name ist Alexander und ich bin ein Web-Entwickler aus Wien, Österreich.
                In diesem Blog teile ich meine Erfahrungen mit einem Fokus auf Barrierefreiheit.
                Hier findest du
                <span> </span>
                <LocalizedLink
                    to="/about"
                    language="de"
                >
                    weitere Infos über diese Seite
                </LocalizedLink>.
            </p>
            <div className={imageWrapper}>
                <StaticImage
                    src="../../images/alex_in_vienna.jpg"
                    alt="Alexander Lehner auf einer Brücke über dem Donaukanal in Wien"
                    placeholder="dominantColor"
                    height={300}
                    width={300}
                />
            </div>
        </>
    );
};

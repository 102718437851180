import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
import * as React from 'react';

import { imageWrapper } from './homepage-content.module.css';

export const HomepageContentEnglish: React.FunctionComponent = () => {
    return (
        <>
            <h2>About This Site</h2>
            <p>
                Hi, my name is Alexander and I'm a web developer from Vienna, Austria.
                In this blog I share my learnings with a focus on web accessibility.
                Here you'll find
                <span> </span>
                <LocalizedLink
                    to="/about"
                    language="en"
                >
                    more info about this site
                </LocalizedLink>.
            </p>
            <div className={imageWrapper}>
                <StaticImage
                    src="../../images/alex_in_vienna.jpg"
                    alt="Alexander Lehner on a bridge over the Danube channel in Vienna"
                    placeholder="dominantColor"
                    height={300}
                    width={300}
                />
            </div>
        </>
    );
};

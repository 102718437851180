import { useRef, useState } from 'react';
import * as React from 'react';

import useHorizontalSwipe from '../../hooks/useHorizontalSwipe';
import { CarouselArrowIcon } from '../svg/carousel-arrow-icon';
import { NewsCarouselConfig, NewsCarouselImagePosition, NewsCarouselItem } from './news-carousel.interface';
import {
    activeItem,
    animateFromLeft,
    animateFromRight,
    controlsContainer,
    imageLink,
    itemsContainer,
    movedOutItem,
    newsCarousel,
    newsItem,
    posLeft,
    posRight,
    slideText,
} from './news-carousel.module.css';

interface NewsCarouselProps {
    config: NewsCarouselConfig;
    newsItems: NewsCarouselItem[];
}

enum AnimationDirection {
    FromLeft = 'FromLeft',
    FromRight = 'FromRight',
    None = 'None',
}

/**
 * Component that displays an accessible, animated, responsive carousel with several news slides.
 * The following style properties can be customized via CSS variables:
 * - "--news-carousel-height": Set the height of the carousel.
 * - "--news-carousel-max-width": Set the max width of the carousel. The carousel's actual width is responsive to its container.
 * - "--news-carousel-button-background": The background (color) of the previous and next buttons.
 * - "--news-carousel-button-color": The color of the arrow icon in the previous and next buttons.
 * - "--carousel-text-background": The background (color) of the slides' text container.
 * - "--carousel-text-color": The text color of the slides' text container.
 * - "--news-carousel-slide-focus-color": The color of the slides' focus indicator.
 */
export const NewsCarousel: React.FunctionComponent<NewsCarouselProps> = ({ config, newsItems }) => {
    const [currentDirection, setCurrentDirection] = useState(AnimationDirection.None);
    const [currentActiveSlide, setCurrentActiveSlide] = useState(0);
    const [lastActiveSlide, setLastActiveSlide] = useState<number | null>(null);

    const ariaLiveRegionText = config.slideDescription + ' ' + (currentActiveSlide + 1) + ' ' + config.slideLabel + ' '
        + newsItems.length + ': ' + newsItems[currentActiveSlide].heading;

    let itemsContainerClass = itemsContainer;
    if (currentDirection !== AnimationDirection.None) {
        itemsContainerClass += currentDirection === AnimationDirection.FromLeft ? ` ${animateFromLeft}` : ` ${animateFromRight}`;
    }

    const getSlideClasses = (index: number): string => {
        let slideClasses = newsItem;
        if (currentActiveSlide === index) {
            slideClasses += ` ${activeItem}`;
        } else if (lastActiveSlide === index) {
            slideClasses += ` ${movedOutItem}`;
        }
        return slideClasses;
    }

    const getSlideImgLinkClasses = (index: number): string => {
        let slideImgLinkClasses = imageLink;
        if (newsItems[index]?.imagePosition === NewsCarouselImagePosition.Left) {
            slideImgLinkClasses += ` ${posLeft}`;
        } else if (newsItems[index]?.imagePosition === NewsCarouselImagePosition.Right) {
            slideImgLinkClasses += ` ${posRight}`;
        }
        return slideImgLinkClasses;
    }

    const showPreviousSlide = (): void => {
        setCurrentDirection(AnimationDirection.FromLeft);
        setLastActiveSlide(currentActiveSlide);
        setCurrentActiveSlide(currentActiveSlide - 1 < 0 ? newsItems.length - 1 : currentActiveSlide - 1);
    }

    const showNextSlide = (): void => {
        setCurrentDirection(AnimationDirection.FromRight);
        setLastActiveSlide(currentActiveSlide);
        setCurrentActiveSlide((currentActiveSlide + 1) % newsItems.length);
    }

    // For each slide: register swipe event handler to show previous/next slide on swipe.
    const swipeContainerRef = newsItems.map(() => {
        const ref = useRef<HTMLDivElement>();
        useHorizontalSwipe(ref, showNextSlide, showPreviousSlide);
        return ref;
    });

    return (
        <section
            aria-label={config.carouselLabel}
            aria-roledescription={config.carouselDescription}
            className={newsCarousel}
        >
            <div className={controlsContainer}>
                <button
                    type="button"
                    aria-label={config.previousButtonLabel}
                    onClick={showPreviousSlide}
                >
                    <CarouselArrowIcon />
                </button>
                <button
                    type="button"
                    aria-label={config.nextButtonLabel}
                    onClick={showNextSlide}
                >
                    <CarouselArrowIcon />
                </button>
            </div>
            <div className={itemsContainerClass}>
                {newsItems.map((item, index) => (
                    <div
                        key={'news-carousel-slide-' + index}
                        className={getSlideClasses(index)}
                        role="group"
                        aria-roledescription={config.slideDescription}
                        aria-label={(index + 1) + ' ' + config.slideLabel + ' ' + newsItems.length}
                        ref={swipeContainerRef[index] as React.MutableRefObject<HTMLDivElement>}
                    >
                        <a
                            href={item.link}
                            className={getSlideImgLinkClasses(index)}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <img
                                src={item.imageUrl}
                                alt=""
                            />
                        </a>
                        <div className={slideText}>
                            <h2>
                                <a href={item.link}>{item.heading}</a>
                            </h2>
                            <p>{item.textSnippet}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div
                className="sr-only"
                aria-live="polite"
            >
                {ariaLiveRegionText}
            </div>
        </section>
    );
}

// extracted by mini-css-extract-plugin
export var activeItem = "news-carousel-module--active-item--63d34";
export var animateFromLeft = "news-carousel-module--animate-from-left--901ae";
export var animateFromRight = "news-carousel-module--animate-from-right--7e61d";
export var controlsContainer = "news-carousel-module--controls-container--68c82";
export var imageLink = "news-carousel-module--image-link--110a3";
export var itemsContainer = "news-carousel-module--items-container--e4342";
export var movedOutItem = "news-carousel-module--moved-out-item--631f0";
export var newsCarousel = "news-carousel-module--news-carousel--06b5d";
export var newsItem = "news-carousel-module--news-item--ee748";
export var posLeft = "news-carousel-module--pos-left--1e3d1";
export var posRight = "news-carousel-module--pos-right--d0e1c";
export var slideInFromLeft = "news-carousel-module--slide-in-from-left--4d26b";
export var slideInFromRight = "news-carousel-module--slide-in-from-right--fefbd";
export var slideOutToLeft = "news-carousel-module--slide-out-to-left--86e29";
export var slideOutToRight = "news-carousel-module--slide-out-to-right--3c75f";
export var slideText = "news-carousel-module--slide-text--9ab63";